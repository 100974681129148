.formInput{
    @apply text-sm text-gray-500 resize-none w-full border border-gray-500 border-opacity-30 bg-white disabled:bg-slate-100 sm:max-h-20 p-2 rounded-lg focus:outline-none
}

.formImageInputBtnWrapper {
    @apply relative inline-block overflow-hidden h-[160px] w-[160px] bg-gray-200 rounded shadow-md;
}

.formImageInputBtnLabel {
    @apply cursor-pointer;
}