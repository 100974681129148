.termsofuse {
    max-width: 100%;
    margin: 40px auto;
    padding: 0 20px;
  }
  
  h1, h2 {
    margin-top: 0;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  h2 {
    margin-top: 40px;
  }