
   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #fff;
  }
  
  .container {
    position: relative;
    background-color: #fff;
    min-height: 100vh; /* Ensures the container takes up full viewport height */
    max-width: 100vw;
  }
  
  .header {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .main {
    text-align: left; 
    padding: 20px; /* Added padding for content spacing */
  }
  
  .main h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .main p {
    font-size: 1em;
    margin-bottom: 20px;
  }
  
  .section {
    margin: 0 auto;
    max-width: 100%;
  }
  
  .section h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .section h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .section p {
    font-size: 1em;
    margin-bottom: 20px;
  }
  
  .solutions {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .solution {
    display: flex;
    align-items: flex-start;
    font-size: 0.875rem;
    color: #333333;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
  
  .icon {
    font-size: 1.5em;
    margin-right: 20px;
  }
  
  .solution h3 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  
  .solution p {
    font-size: 1em;
  }
  
  .phoneImage {
    position: absolute;
    top: 40%;
    right: 0;
    transform: translate(0, -50%);
  }
  
  .phoneImage img {
    width: auto;
    height: 500px;
    max-width: 750px;
    display: block;
  }
  
  .footer {
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .madeWith {
    color: red;
  }
  
  .purpleBanner {
    background-color: rgb(245, 206, 245);
    color: white;
    padding: 20px;
    margin-top: 20px;
    font-size: 1.2em;
    border-radius: 5px;
  }
  