.SubmissionPage {
    display: flex;
    flex-direction: row;
    gap: 1em;
    height: 100%;

    .col {
        // margin-top: 2em;
        width: 50%;
        display: flex;
        flex-direction: column;
        
        gap: 2em;
        

        h1 {
            margin: 0 1em;
        }

        .drop-down {
            background-color: #D8D8D8;
            width: 100%;
            padding: 1em;
            margin: 0 2em;
            border-width: 0;
            border-radius: 10px;
        }

        .VideoPlayer-box {
            // background-color: #D8D8D8;
            margin-left: 1.5em;
            border-radius: 10px;
        }

        .simThreshold {
            input {
                margin: 3.5em 2em;
                width: 100%;
                // height: 2em;
                background: #d3d3d3;
                cursor: pointer;
                display: None;

            }

            .threbtns {
                height: 4.5em;
                margin-left: 2em;
                display: flex;
                justify-content: space-between;
                gap: 1em;
                margin-top: 1em;
                margin-bottom: 1em;
                .thres {
                    padding: 1em;
                    width: 100%;
                    min-width: 10em;
                    border-radius: 20px;
                    border-width: 0;

                }
                :hover {
                    background: #e1e1e1;
                }
            }
        }

        .WebcamDiv {
            margin-left: 2em;
            height: fit-content;
            width: 100%;
            // background-color: #D8D8D8;
            .toolbar {
                background-color: #c0e8ff;
                padding: .5em;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-radius: 10px;
                // width: 100%;

                div {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                button {
                    background-color: #fff;
                    border-radius: 5px;
                    height: 2em;
                    border: none;

                }

                
                .btn.disabled {
                    opacity: 0.5; 
                    pointer-events: none;
                    cursor: not-allowed; 
                }
            }
        }

        .WebcamDivOptions {
            margin-left: 2em;
            // height: fit-content;
            background-color: #D8D8D8;
            // height: 100%;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1em;

            .btn {
                display: flex;
                border-style: dashed;
                border-radius: 10px;
                border-color: rgba(93, 94, 94, 0.521);
                border-width: 1px;
                padding: 1em 3em;
                gap: 1em;


            }

            .Icon {
                color: #3AB6FC;
            }

            :hover {
                background-color: rgb(177, 241, 220);
            }
        }
    }

    @media (max-width: 1032px) {

        .col {
            .simThreshold {
                .threbtns {
                    flex-direction: column;
                    margin-left: 1em;
                    gap: 1px;

                    .thres {
                        padding: 3px;
                        width: 100%;
                        // min-width: 10em;
                        border-radius: 20px;
                        border-width: 0;

                        button {
                            width: 90%;
                            padding: 0;
                        }

                    }
                }
            }
        }


    }

    @media (max-width: 768px) {
        flex-direction: column;

        .mobileCol {
            .col {
                width: 100%;

                .drop-down {
                    width: 85%;
                }

                .VideoPlayer-box {
                    width: 85%;
                    .mobileVideoPlayerBoxDummy{
                        width: 85%;
                    }
                }
                .WebcamDivOptions{
                    // padding-top: 1em;
                    .btn{
                        padding: 0.1em 1em;
                    }
                }

                .WebcamDiv {
                    width: 85%;
                }

                .simThreshold {
                    .threbtns {
                        flex-direction: row;
                        width: 85%;
                    }
                }
            }
        }
    }
}
// .sim-DropDown {
//     display: flex;
//     flex-direction: column; 
// }

.radio-options {
    display: flex;
    flex-direction: row; 
    gap: 10px; 
}

.radio-label {
    display: flex;
    align-items: center; 
    margin-right: 10px; 
}