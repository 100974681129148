html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* styles to hide scrollbar */
*::-webkit-scrollbar {
    width: 0em;
}

*::-webkit-scrollbar-thumb {
    background-color: transparent;
}

/* Hide scrollbar for Firefox */
* {
    scrollbar-width: none;
}

*::-moz-scrollbar {
    width: 0em;
}

*::-moz-scrollbar-thumb {
    background-color: transparent;
}

.thin-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #6b7280 #d1d5db;
}

.thin-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
    background-color: #4b5563;
    border-radius: 10px;
}

.thin-scrollbar::-webkit-scrollbar-track {
    background-color: #1f2937;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes pulsate {
    0% {
        transform: scale(0.75);
    }
    50% {
        transform: scale(1.75);
    }
    100% {
        transform: scale(0.75);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@layer utilities {
    .rotate-animation {
        @apply animate-rotate;
    }

    .pulsate-animation {
        @apply animate-pulsate;
    }

    @keyframes pulsate {
        0% {
            transform: scale(0.75);
        }
        50% {
            transform: scale(1.75);
        }
        100% {
            transform: scale(0.75);
        }
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .animate-pulsate {
        animation: pulsate 1.25s infinite ease;
    }

    .animate-rotate {
        animation: rotate 2s infinite linear;
    }
}

@layer components {
    .font-raleway {
        font-family: 'Raleway', sans-serif;
    }
}

@layer base {
    .reset-all * {
        all: revert;
    }
}

.showcase-video-thumbnail video {
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .heroimage {
        scale: 0.6;
    }
}
@media screen and (max-width: 1024px) {
    .heroimage {
        margin-top: -10%;
        scale: 0.6;
        width: 130vw;
        left: -15%;
        overflow: visible;
    }
}
@media screen and (max-width: 1280px) {
    .heroimage {
        scale: 0.8;
        width: 170vw;
        left: -5%;
    }
}
@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .heroimage {
        scale: 0.7;
        width: 120vw;
    }
    @media screen and (min-width: 1441px) and (max-width: 1536px) {
        .heroimage {
            left: 25%;
            scale: 0.4;
        }
    }
}
@media screen and (min-width: 1537px) and (max-width: 1557px) {
    .heroimage {
        scale: 0.8;
        left: 3%;
    }
}
@media screen and (min-width: 1558px) {
    .heroimage {
        scale: 1.1;
        left: 15%;
    }
}
@media screen and (max-width: 768px) {
}

.gallery-container {
    background-color: #eeeeee;
    border-radius: 25px;
    width: 500px;
    height: 500px;
    margin: 0;
    padding: 0 20px 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: space-between;
    list-style: none;
}

.gallery-item {
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    margin: 20px 0 0 20px;
    flex: 1 1 100px;
}

.single-image-container * {
    pointer-events: all;
}

iframe#webpack-dev-server-client-overlay {
    display: none;
}

.splash-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #000;
    height: 100vh;
    width: 100vw;
    animation: lift 0.8s ease-in-out;
    animation-delay: 1.6s;
    animation-fill-mode: forwards;
}

.title {
    font-size: 81px;
    font-weight: 800;
    color: #fff;
    text-align: center;
    font-family: 'Lexend Deca', sans-serif;
    animation:
        wave 0.4s,
        jump 1s;
    position: relative;
    top: 0;
    padding: 4px;
    opacity: 0;
    z-index: 3;
    animation-fill-mode: forwards;
}

span:nth-of-type(1) {
    animation:
        wave 0.4s,
        jump 1.1s ease-in-out alternate 0.05s;
}

span:nth-of-type(2) {
    animation:
        wave 0.4s,
        jump 1.1s ease-in-out alternate 0.1s;
}

span:nth-of-type(3) {
    animation:
        wave 0.4s,
        jump 1.1s ease-in-out alternate 0.15s;
}

span:nth-of-type(4) {
    animation:
        wave 0.4s,
        jump 1.1s ease-in-out alternate 0.2s;
}

span:nth-of-type(5) {
    animation:
        wave 0.4s,
        jump 1.1s ease-in-out alternate 0.25s;
}

span:nth-of-type(6) {
    animation:
        wave 0.4s,
        jump 1.1s ease-in-out alternate 0.3s;
}

@keyframes wave {
    0% {
        top: 0%;
    }
    100% {
        top: 100%;
    }
}

@keyframes jump {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 0;
    }
    90% {
        transform: translate3d(0, -16%, 0);
        opacity: 1;
    }
    100% {
        transform: translate3d(0, -32%, 0);
        opacity: 1;
    }
}

@keyframes lift {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
    }
    100% {
        transform: translate3d(0, -100%, 0);
        opacity: 1;
        visibility: hidden;
        display: none;
    }
}

@keyframes appear {
    0% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Protest+Strike&family=Qwitcher+Grypen:wght@700&family=Rubik+Dirt&family=Spicy+Rice&display=swap');

.rubik-dirt-regular {
    font-family: 'Rubik Dirt', system-ui;
    font-weight: 400;
    font-style: normal;
}

.slide-animated-text {
    mix-blend-mode: overlay;
    background: radial-gradient(ellipse, black 50%, white 70%) white no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: slide 6s infinite ease-in-out;
    animation: slide 6s infinite ease-in-out;
}
@-webkit-keyframes slide {
    0% {
        background-position: -100vh center;
    }
    60%,
    100% {
        background-position: 100vh center;
    }
}
@keyframes slide {
    0% {
        background-position: -100vh center;
    }
    60%,
    100% {
        background-position: 100vh center;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Nav item animation */
.nav-item-button {
    cursor: pointer;
    position: relative;
    border: none;
    background: none;
    text-transform: uppercase;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
}

.nav-item-button:focus,
.nav-item-button:hover {
    color: #000000;
}

.nav-item-button:focus:after,
.nav-item-button:hover:after {
    width: 100%;
    left: 0%;
}

.nav-item-button:after {
    content: '';
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #000000;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
}

@keyframes blob-bounce {
    0% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }

    25% {
        transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }

    50% {
        transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }

    75% {
        transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }

    100% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
}
